<template>
	<li :class="['is-' + item.slug, {'is-active': item.name === diet}]">
		<button
			type="button"
			@click="changeFilter('diet', item.name)"
		>
			<span>{{ item.name }}</span>
			<span class="count">{{ count }}</span>
		</button>
	</li>
</template>

<script>
import {mapState} from 'vuex';
import {FilterMixin} from '@/mixins';


export default {
	mixins: [FilterMixin],
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapState(['diet', 'diets']),
		count() {
			// @TODO: Update count depending on filter
			return this.item.count;
		}
	}
};
</script>
